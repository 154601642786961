<template>
    <div class="forgot-password">
        <nav-bar />
        <div class="forgot-password-container container">
            <transition name="slide-down">
                <div v-if="isRequestSent" class="row">
                    <div class="col">
                        <h1>Instructions sent</h1>
                        <p class="mb-4">
                            You will be receiving your instructions to reset your password in a couple seconds
                        </p>
                        <svg
                            width="14"
                            height="11"
                            viewBox="0 0 14 11"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M0.639999 5.12086L4.88 9.36086L13.36 0.880859" stroke="#05A88F" stroke-linecap="round" />
                        </svg>
                    </div>
                </div>
            </transition>
            <div v-if="!isRequestSent" class="row">
                <div class="col">
                    <h1>Forgot your password?</h1>
                    <p>Let us help you out. Enter your email address bellow and we’ll send you instructions to reset your password.</p>
                    <ValidationObserver v-slot="{ valid, handleSubmit }" slim>
                        <form novalidate @submit.prevent="handleSubmit(forgotPassword)">
                            <ValidationProvider v-slot="{ errors }" name="email" rules="required|email">
                                <div :class="{ 'required' : errors.length }" class="form-group">
                                    <label for="email">Your email</label>
                                    <span v-if="errors.length">({{ errors[0] }})</span>
                                    <input
                                        id="email"
                                        v-model.trim="email"
                                        type="email"
                                        name="email"
                                        class="form-control"
                                        placeholder="Enter your email"
                                    >
                                </div>
                            </ValidationProvider>
                            <button :disabled="!valid" class="btn btn-primary">
                                Send
                            </button>
                        </form>
                    </validationObserver>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ForgotPassword",
    components: {
        NavBar: () => import(/* webpackChunkName: "nav-bar" */ "@c/organisms/nav-bar")
    },
    data() {
        return {
            isRequestSent: false,
            email: ""
        }
    },
    methods: {
        forgotPassword() {
            axiosPublic.post(`/auth/forgot`, { email: this.email }).then(({ data }) => {
                this.isRequestSent = true;
                console.log(data)
            }).catch((error) => {
                console.log(error.response.data.errors.message)
            })
        }
    }
}
</script>
